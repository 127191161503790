import { Box, Typography } from '@mui/material';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { SvgWrapper } from 'components/svg';
import { Table } from 'components/table';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductReceiptRequestsApiData } from 'services/types/productReceipt';
import { formattedNumber } from 'utilities/helperFunc/formatter';
import { ReactComponent as ChevronRight } from 'assets/custom-svg/tableRightChevron.svg';
import { Chip } from 'components/chip';
import { OrganizationType } from 'services/enums/organization';

interface RequestSentTableProps {
  data: ProductReceiptRequestsApiData[];
  isLoading: boolean;
  organizationType: string;
}
const RequestSentTable: React.FC<RequestSentTableProps> = ({
  data,
  isLoading,
  organizationType
}) => {
  const navigate = useNavigate();

  const columnHelper = createColumnHelper<ProductReceiptRequestsApiData>();
  const columns = [
    columnHelper.accessor('merchant', {
      header: 'MERCHANT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('product.name', {
      header: 'PRODUCT',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {info.getValue()}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_request_price', {
      header: 'TOTAL VALUE',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          ₦{formattedNumber(info.getValue())}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('total_request_quantity', {
      header: 'QUANTITY',
      cell: info => (
        <Typography variant="bodyMediumMedium" color="#475467">
          {formattedNumber(info.getValue())}
        </Typography>
      ),
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    }),
    columnHelper.accessor('status', {
      header: 'STATUS',
      cell: info => <Chip label={info.getValue()} size="sm" />,
      footer: info => info.column.id,
      sortingFn: 'alphanumeric'
    })
  ] as ColumnDef<ProductReceiptRequestsApiData>[];

  const updateColumnDefForOrgs = (organizationType: string) => {
    if (organizationType === OrganizationType.MERCHANT) {
      columns.splice(
        0,
        0,
        columnHelper.accessor('customer', {
          id: 'customer',
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>,
        columnHelper.accessor('lender', {
          id: 'intermediary',
          header: 'INTERMEDIARY',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
      columns.splice(
        5,
        0,
        columnHelper.accessor('is_batch', {
          header: 'TYPE',
          cell: info => (
            <Chip label={info.getValue() ? 'Batch' : 'Single'} size="sm" />
          )
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
    } else if (organizationType === OrganizationType.LENDER) {
      columns.splice(
        0,
        0,
        columnHelper.accessor('merchant', {
          header: 'MERCHANT',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>,
        columnHelper.accessor('customer', {
          header: 'CUSTOMER',
          cell: info => (
            <Typography variant="bodyMediumMedium" color="#475467">
              {info.getValue()}
            </Typography>
          ),
          footer: info => info.column.id,
          sortingFn: 'alphanumeric'
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
      columns.splice(
        5,
        0,
        columnHelper.accessor('status', {
          id: 'status',
          header: 'STATUS',
          cell: info => <Chip label={info.getValue()} size="sm" />
        }) as ColumnDef<ProductReceiptRequestsApiData>
      );
    }
  };

  updateColumnDefForOrgs(organizationType);
  return (
    <Box>
      <Table<ProductReceiptRequestsApiData>
        hover
        columns={columns}
        data={data}
        enableFilter={false}
        loadingTable={isLoading}
        showSearch={false}
        onClickRow={rowData =>
          navigate(`/request-sent/${rowData.request_id}/details`)
        }
        hideCheckboxes={true}
        tableTitleType="product-receipt-history-table"
        customOptionsIcon={
          <SvgWrapper icon={ChevronRight} width="2.25rem" height="2.25rem" />
        }
      />
    </Box>
  );
};
export default RequestSentTable;
