import React from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { ReactComponent as AiStars } from 'assets/custom-svg/aiStars.svg';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import { ReactComponent as EditIcon } from 'assets/svg/prcEdit.svg';
import { ReactComponent as CloseIcon } from 'assets/svg/x.svg';
import { Accordion } from 'components/accordion';
import { useContext, useEffect, useState } from 'react';
import { RequestProductReceiptContext } from '../context/requestProductReceiptContext';
import RequestProductReceiptBottomNav from '../nav/bottomNav';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Button } from 'components/button';
import { FormInput } from 'components/form';
import { SvgWrapper } from 'components/svg';
import { useForm } from 'react-hook-form';

type ModalFormValues = {
  quantity: string;
  amount: string; // read-only field
};

const ProductReceiptBreakdown = () => {
  const { data, updateFormData } = useContext(RequestProductReceiptContext);

  // We’ll rely on the context arrays for quantity & amount
  const { quantity, amount, productPrice } = data;

  // Local states for the Edit Modal
  const [openEditIndex, setOpenEditIndex] = useState<number | null>(null);

  // React Hook Form setup for the modal
  const { control, handleSubmit, watch, setValue, reset } =
    useForm<ModalFormValues>({
      defaultValues: {
        quantity: '',
        amount: ''
      }
    });

  // Watch for changes to 'quantity' and recalc 'amount'
  const watchQuantity = watch('quantity');
  useEffect(() => {
    const qtyNum = parseFloat(watchQuantity) || 0;
    const priceNum = parseFloat(productPrice) || 0;
    setValue('amount', (qtyNum * priceNum).toString());
  }, [watchQuantity, productPrice, setValue]);

  const handleOpenEditModal = (index: number) => {
    setOpenEditIndex(index);

    // Pre-fill the form with current batch values
    const initialQty = quantity[index] ?? '0';
    const priceNum = parseFloat(productPrice) || 0;
    reset({
      quantity: initialQty,
      amount: (parseFloat(initialQty) * priceNum).toString()
    });
  };

  const handleCloseEditModal = () => {
    setOpenEditIndex(null);
  };

  // On submit, update the quantity array in context
  const handleSavedEditModal = (values: ModalFormValues) => {
    if (openEditIndex !== null) {
      const newQuantity = [...quantity];
      newQuantity[openEditIndex] = values.quantity;
      // Mark as manual so auto-batch logic in earlier steps won't override
      updateFormData({ quantity: newQuantity, isQuantityManual: true });
      setOpenEditIndex(null);
    }
    console.log('i ran saved edit modal');
  };

  // Compute total amount from context arrays
  const totalAmount = amount.reduce(
    (acc, curr) => acc + parseFloat(curr || '0'),
    0
  );

  return (
    <>
      {/* EDIT DIALOG */}
      <Dialog
        open={openEditIndex !== null}
        onClose={handleCloseEditModal}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0.75rem'
          }
        }}
      >
        <Box sx={{ px: '0.125rem', width: '26.25rem' }}>
          <DialogContent
            sx={{ borderRadius: '.75rem .75rem 0rem 0rem', padding: 0 }}
          >
            <>
              <Stack
                sx={{
                  flexDirection: 'row',
                  background: '#FFF',
                  pb: '1rem',
                  py: '1rem'
                }}
              >
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  width="100%"
                  px="1rem"
                >
                  <Stack direction="column" alignItems="flex-start">
                    <Typography variant="h7Bold" color="#475467">
                      {openEditIndex !== null
                        ? `Product Receipt ${openEditIndex + 1}`
                        : 'Product Receipt'}
                    </Typography>
                    <Typography variant="bodyMediumRegular" color="#667085">
                      Changes made here will reflect on this product receipt
                      only
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      cursor: 'pointer'
                    }}
                    onClick={handleCloseEditModal}
                  >
                    <SvgWrapper
                      icon={CloseIcon}
                      width="1.25rem"
                      height="1.25rem"
                      styleOverrides={{ fill: '#475467', stroke: 'none' }}
                    />
                  </Box>
                </Box>
              </Stack>

              <Divider
                sx={{
                  color: '#F5F6F7',
                  border: '.06rem solid',
                  mt: '-.15rem',
                  mb: '.5rem'
                }}
              />
            </>
            <form onSubmit={handleSubmit(handleSavedEditModal)}>
              <Box px="1rem">
                <FormInput<ModalFormValues>
                  name="amount"
                  label="AMOUNT"
                  control={control}
                  readonly
                />
                <FormInput<ModalFormValues>
                  name="quantity"
                  label="PRODUCT QUANTITY"
                  control={control}
                  type="number"
                  min={1}
                />
              </Box>

              <Stack
                direction="row"
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '.75rem',
                  mb: '1rem',
                  px: '1rem'
                }}
              >
                <Button
                  color="grey"
                  transparent
                  size="lg"
                  text="Cancel"
                  styleOverrides={{
                    borderRadius: '.5rem',
                    border: '.094rem solid #F2F4F7',
                    color: '#475467',
                    width: '50%'
                  }}
                  onClick={handleCloseEditModal}
                />

                <Button
                  color="primary"
                  size="lg"
                  text={'Save'}
                  styleOverrides={{
                    borderRadius: '.5rem',
                    width: '50%'
                  }}
                  submit
                />
              </Stack>
            </form>
          </DialogContent>
        </Box>
      </Dialog>
      {/* END EDIT DIALOG */}

      <Box
        sx={{
          width: '56.25rem',
          marginBottom: '6.25rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0.0625rem solid #EAECF0',
          borderRadius: '0.5rem'
        }}
      >
        <Grid container spacing={0} sx={{ margin: 0, padding: 0, py: 0 }}>
          {/* Left Panel */}
          <Grid
            item
            xs={7}
            sx={{
              borderRight: '0.12rem solid #EAECF0',
              background: '#fff'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: 'auto',
                mt: '1.5rem',
                borderRadius: '.75rem 0 0 .75rem',
                background: '#fff'
              }}
            >
              {/* Step Header */}
              <Stack
                sx={{
                  p: '1rem',
                  pt: '1.25rem',
                  borderBottom: '.063rem solid #F5F6F7',
                  gap: '.25rem'
                }}
              >
                <Typography variant="h6Bold" color="#475467">
                  Product Receipt Breakdown
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  Below is a breakdown of product receipts based on bank
                  guarantee details
                </Typography>
              </Stack>

              <Divider
                sx={{
                  color: '#F2F4F7',
                  border: '.06rem solid',
                  mb: '1.5rem'
                }}
              />

              <Box
                sx={{
                  p: '1rem'
                }}
              >
                {/* Profitability (sample) */}
                <Stack
                  direction="row"
                  alignItems="center"
                  gap=".75rem"
                  sx={{
                    borderRadius: '0.5rem',
                    background: '#F7FCFA',
                    mb: '1rem'
                  }}
                >
                  <SvgWrapper
                    icon={AiStars}
                    width="3.75rem"
                    height="3.75rem"
                    styleOverrides={{
                      fill: '#F7FCFA'
                    }}
                  />

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Typography variant="bodyMediumSemibold" color="#344054">
                      Predictions for profitability
                    </Typography>
                    <Typography variant="captionXSmall" color="#667085">
                      90% Accuracy
                    </Typography>
                  </Box>
                </Stack>

                {/* Payment Interval (sample) */}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  gap=".75rem"
                  sx={{
                    borderRadius: '0.5rem',
                    background: '#F8FBFE',
                    border: '.063rem solid #F2F4F7',
                    mb: '1rem',
                    padding: '.75rem 0rem'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '1.5rem',
                      width: '1.5rem',
                      borderRadius: '.5rem',
                      backgroundColor: '#ECF2FE',
                      padding: '0.375rem'
                    }}
                  >
                    <SvgWrapper
                      icon={InfoIcon}
                      width=".75rem"
                      height=".75rem"
                      color="#3E7DF8"
                    />
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                  >
                    <Typography variant="bodyMediumSemibold" color="#344054">
                      Payment interval of {data.repayment_schedule_value}-day
                      payment, with early payment allowed
                    </Typography>
                  </Box>
                </Stack>
              </Box>

              <Divider
                sx={{
                  color: '#F2F4F7',
                  border: '.06rem solid',
                  mb: '1.5rem'
                }}
              />
              <Box
                sx={{
                  py: '1.25rem',
                  px: '1rem',
                  border: '0.0625rem solid #F5F6F7'
                }}
              >
                <Box
                  sx={{
                    borderRadius: '0.75rem',

                    border: '0.0625rem solid #F5F6F7'
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '0.0625rem solid #F5F6F7',
                      padding: '1.25rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Batch
                    </Typography>
                    <Typography variant="bodyMediumSemibold" color="#475467">
                      {quantity.length}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    sx={{
                      borderBottom: '0.0625rem solid #F5F6F7',
                      padding: '1.25rem'
                    }}
                  >
                    <Typography variant="bodyMediumMedium" color="#667085">
                      Repayment time
                    </Typography>
                    <Typography variant="bodyMediumSemibold" color="##475467">
                      Every {data.repayment_schedule_value} Days
                    </Typography>
                  </Box>

                  <Box
                    borderRadius=".75rem"
                    border={'0.0625rem solid #F5F6F7'}
                    sx={{
                      backgroundColor: 'rgba(250, 251, 252, 0.99)',
                      my: '0.5rem',
                      mx: '0.75rem'
                    }}
                  >
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      px="1.25rem"
                      py="1rem"
                    >
                      <Typography variant="bodyMediumMedium" color="#667085">
                        Merchant
                      </Typography>
                      <Typography variant="bodyMediumSemibold" color="#475467">
                        {data.merchant || 'Merchant not set'}
                      </Typography>
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      px="1.25rem"
                      py="1rem"
                    >
                      <Typography variant="bodyMediumMedium" color="#667085">
                        Customer
                      </Typography>
                      <Typography variant="bodyMediumSemibold" color="#475467">
                        {data.customer_name || 'Customer not set'}
                      </Typography>
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      px="1.25rem"
                      py="1rem"
                    >
                      <Typography variant="bodyMediumMedium" color="#667085">
                        Product
                      </Typography>
                      <Typography variant="bodyMediumSemibold" color="#475467">
                        {data.productName || 'Product not set'}
                      </Typography>
                    </Stack>
                    <Stack
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      px="1.25rem"
                      py="1rem"
                    >
                      <Typography variant="bodyMediumMedium" color="#667085">
                        Unit
                      </Typography>
                      <Typography variant="bodyMediumSemibold" color="#475467">
                        {data.unit || 'Unit not set'}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Right Panel: Receipts */}
          <Grid
            item
            xs={5}
            sx={{
              backgroundColor: 'rgba(250, 251, 252, 0.99)'
            }}
          >
            <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                background: 'rgba(250, 251, 252, 0.99)',
                height: '100%',
                px: '0.75rem',
                py: '1.25rem'
              }}
            >
              <Box sx={{ maxHeight: '80rem', overflowY: 'auto' }}>
                {quantity.map((qty, index) => {
                  const amt = parseFloat(amount[index] ?? '0') || 0;
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        borderRadius: '0.5rem',
                        border: '0.0625rem solid #F5F6F7',
                        background: '#FFFFFF',
                        pb: '0rem',
                        mb: '1rem'
                      }}
                    >
                      <Accordion
                        summary={
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              borderRadius: '0.75rem',
                              width: '100%'
                            }}
                          >
                            <Typography
                              variant="bodyMediumSemibold"
                              color="#344054"
                            >
                              Product Receipt {index + 1}
                            </Typography>
                            {/* EDIT Button */}
                            <IconButton
                              onClick={e => {
                                e.stopPropagation(); // prevent Accordion toggle
                                handleOpenEditModal(index);
                              }}
                            >
                              <EditIcon
                                stroke="#667085"
                                height="1.25rem"
                                width="1.25rem"
                              />
                            </IconButton>
                          </Stack>
                        }
                      >
                        <Stack
                          sx={{
                            mt: '1rem',
                            background: '#fbfbfc',
                            border: '0.0625rem solid #F5F6F7',
                            py: '0.75rem',
                            borderRadius: '0.5625rem',
                            mb: '1rem'
                          }}
                          gap="1rem"
                        >
                          <Stack
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            py=".5rem"
                            px="1.25rem"
                          >
                            <Typography
                              variant="bodyMediumMedium"
                              color="#667085"
                            >
                              Amount
                            </Typography>
                            <Typography
                              variant="bodyMediumSemibold"
                              color="#475467"
                            >
                              ₦ {amt.toLocaleString()}
                            </Typography>
                          </Stack>

                          <Divider
                            sx={{
                              color: '#F2F4F7',
                              border: '.06rem solid'
                            }}
                          />

                          <Stack
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            py=".5rem"
                            px="1.25rem"
                          >
                            <Typography
                              variant="bodyMediumMedium"
                              color="#667085"
                            >
                              Quantity
                            </Typography>
                            <Typography
                              variant="bodyMediumSemibold"
                              color="#475467"
                            >
                              {qty}
                            </Typography>
                          </Stack>

                          <Divider
                            sx={{
                              color: '#F2F4F7',
                              border: '.06rem solid'
                            }}
                          />

                          <Stack
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            justifyContent="space-between"
                            py=".5rem"
                            px="1.25rem"
                          >
                            <Typography
                              variant="bodyMediumMedium"
                              color="#667085"
                            >
                              Date of Repayment
                            </Typography>
                            <Typography
                              variant="bodyMediumSemibold"
                              color="#475467"
                            >
                              {'31st Aug, 2024'}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Typography variant="bodyMediumSemibold" color="#344054">
        Total Amount: ₦ {totalAmount ? totalAmount.toLocaleString() : '0'}
      </Typography>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        <RequestProductReceiptBottomNav
          nextButtonLabel="Next"
          isNextDisabled={false}
        />
      </Box>
    </>
  );
};
export default ProductReceiptBreakdown;
