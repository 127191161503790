import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import RequestProductReceiptBottomNav from '../nav/bottomNav';
import { ReactComponent as InfoIcon } from 'assets/svg/prcInfo.svg';
import { ReactComponent as Automation } from 'assets/svg/automation.svg';
import { ReactComponent as AiStars } from 'assets/custom-svg/aiStars.svg';
import { SvgWrapper } from 'components/svg';
import { Chip } from 'components/chip';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid
} from 'recharts';
import {
  formatLargeAmount,
  formattedNumber
} from 'utilities/helperFunc/formatter';
import CustomGauge from 'components/charts/gauge';
import { RequestProductReceiptContext } from '../context/requestProductReceiptContext';
import {
  useGetFacilityPaymentAnalyticsQuery,
  useGetFacilityPaymentProbabilityQuery,
  useGetFacilityUtilizationAnalyticsQuery
} from 'services/api/productReceiptApi';

const Analytics = () => {
  const { data } = useContext(RequestProductReceiptContext);

  const {
    data: paymentAnalyticsData,
    isLoading,
    isError
  } = useGetFacilityPaymentAnalyticsQuery({
    distributor: data.customerID
  });

  const {
    data: utilizationAnalyticsData,
    isLoading: isUtilLoading,
    isError: isUtilError
  } = useGetFacilityUtilizationAnalyticsQuery({
    distributor: data.customerID
  });

  const {
    data: onTimeProbabilityData,
    isLoading: isOntimeProbLoading,
    isError: isOntimeProbError
  } = useGetFacilityPaymentProbabilityQuery({
    distributor: data.customerID,
    probability_type: 'timely_payment'
  });

  const {
    data: guaranteeUtilizationData,
    isLoading: isGuaranteeUtilizationLoading,
    isError: isGuaranteeUtilizationError
  } = useGetFacilityPaymentProbabilityQuery({
    distributor: data.customerID,
    probability_type: 'guarantee_utilization'
  });

  const paymentData = useMemo(() => {
    return paymentAnalyticsData || [];
  }, [paymentAnalyticsData]);

  const utilizationData = useMemo(
    () => utilizationAnalyticsData || [],
    [utilizationAnalyticsData]
  );

  return (
    <>
      <Box
        sx={{
          width: '55.5rem',
          marginBottom: '6.25rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderRadius: '0.5rem'
        }}
      >
        <Stack
          sx={{
            padding: '1rem',
            paddingTop: '1.25rem',
            borderBottom: '.063rem solid #F5F6F7',
            gap: '.25rem',
            backgroundColor: 'inherit',
            mb: '1rem'
          }}
        >
          <Typography variant="h6Bold" color="#475467">
            Analytics
          </Typography>
          <Typography variant="bodyMediumRegular" color="#667085">
            Assess the profitability of this bank guarantee based on the
            customer&rsquo;s repayment history.
          </Typography>
        </Stack>

        <Box
          sx={{
            background: '#fff',
            width: '100%',
            borderRadius: '0.5rem',
            overflow: 'auto',
            padding: '1.5rem'
          }}
        >
          {/* Bank and Customer request display info text */}
          <Box
            sx={{
              display: 'flex',
              py: '0.75rem',
              px: '1rem',
              flexDirection: 'row',
              alignItem: 'center',
              backgroundColor: '#F8FBFE',
              borderRadius: '0.5rem',
              gap: '1rem',
              mb: '1rem'
            }}
          >
            <SvgWrapper
              icon={InfoIcon}
              width="1.5rem"
              height="1.5rem"
              styleOverrides={{
                fill: '#ECF2FE',
                stroke: '#C3D7FD'
              }}
            />
            <Typography
              variant="bodySmallSemibold"
              color="#344054"
              sx={{
                fontSize: '0.75rem',
                fontWeight: 500
              }}
            >
              Repayment time for this facility is{' '}
              {data.repayment_schedule_value} days
            </Typography>
          </Box>

          {/* Analytics info cards */}
          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '1rem'
            }}
          >
            {[
              { title: 'Facility Amount' },
              {
                title: 'Estimated Fee'
              },
              {
                title: 'Default Recovery Rate'
              }
            ].map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    border: '0.0625rem solid #F5F6F7',
                    borderRadius: '0.5rem',
                    padding: '0.75rem',
                    width: '16.67rem',
                    height: '4.813rem'
                  }}
                >
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      gap: '1rem',
                      alignItems: 'center',
                      mb: '0.75rem'
                    }}
                  >
                    <SvgWrapper
                      icon={Automation}
                      height="1rem"
                      width="1rem"
                      styleOverrides={{
                        fill: '#98A2B3'
                      }}
                    />
                    <Typography variant="bodySmallMedium" color={'#667085'}>
                      {item.title}
                    </Typography>
                  </Stack>

                  <Stack
                    sx={{
                      flexDirection: 'row',
                      gap: '1rem',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography variant="h6Bold" color={'#344054'}>
                      {item.title === 'Facility Amount'
                        ? ' ₦' + formattedNumber(data.facility_amount)
                        : item.title === 'Estimated Fee'
                          ? ' ₦' + formattedNumber(data.guarantee_fee)
                          : '98%'}
                    </Typography>
                    {item.title === 'Estimated Fee' && (
                      <Chip size="sm" label="+ 1%" color="success" />
                    )}
                  </Stack>
                </Box>
              );
            })}
          </Box>

          {/* Payment Trends */}
          <Box
            sx={{
              borderRadius: '0.5rem',
              border: '0.0625rem solid #F5F6F7',
              background: '#FFF',
              boxShadow:
                '0px 5px 13px -5px rgba(16, 25, 40, 0.05), 0px 2px 4px -1px rgba(16, 25, 40, 0.02)',
              marginBottom: '1.5rem'
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: '0.75rem',
                px: '1.75rem'
              }}
            >
              <Box
                sx={{
                  width: '50%'
                }}
              >
                <Typography
                  variant="bodyLargeSemibold"
                  color="#344054"
                  display={'block'}
                >
                  Payment Trend
                </Typography>
                <Typography
                  variant="bodyMediumMedium"
                  color="#98A2B3"
                  display="block"
                >
                  See predictions of payments for this facility
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '50%',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '1rem',
                  marginBottom: '1.5rem'
                }}
              >
                {['Expected Payment', 'Received Payment'].map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        borderRadius: '0.5rem',
                        py: '0.5rem',
                        px: '0.75rem',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        maxWidth: 'fit-content',
                        border: '0.0625rem solid #E5E5EF'
                      }}
                    >
                      {/* Colored Dot */}
                      <Box
                        sx={{
                          width: '0.51725rem;',
                          height: '0.51725rem;',
                          backgroundColor: index === 0 ? '#6597F9' : '#FDEAC3', // Adjust to match exact blue
                          borderRadius: '50%'
                        }}
                      />

                      {/* Text */}
                      <Typography variant="bodySmallMedium" color="#1D2939">
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Divider
              sx={{
                color: '#F2F4F7',
                border: '.06rem solid',
                mb: '1rem'
              }}
            />
            {/* Chart */}
            <Box>
              {/* Payment Trend Chart */}
              <Box mt={3}>
                {isLoading ? (
                  <Typography>Loading...</Typography>
                ) : isError ? (
                  <Typography>Error loading data</Typography>
                ) : (
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                      data={paymentData}
                      barCategoryGap={30}
                      barGap={3}
                      width={100}
                    >
                      <CartesianGrid strokeDasharray="3 3" vertical={false} />
                      <XAxis dataKey="name" />
                      <YAxis
                        tickFormatter={value =>
                          formatLargeAmount(value, true, false)
                        }
                      />
                      <Tooltip />
                      <Legend />

                      <Bar
                        dataKey="Expected"
                        fill="#6597F9"
                        barSize={20}
                        radius={[4, 4, 0, 0]}
                      />
                      <Bar
                        dataKey="Received"
                        fill="#FDEAC3"
                        barSize={20}
                        radius={[4, 4, 0, 0]}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Box>
          </Box>

          {/* Bank Guarantee Utilization */}
          <Box
            sx={{
              borderRadius: '0.5rem',
              border: '0.063rem solid #F5F6F7',
              background: '#FFF',
              boxShadow:
                '0px 5px 13px -5px rgba(16, 25, 40, 0.05), 0px 2px 4px -1px rgba(16, 25, 40, 0.02)',
              marginBottom: '1.5rem'
            }}
          >
            {/* Header */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: '0.75rem',
                px: '1.75rem'
              }}
            >
              <Box
                sx={{
                  width: '50%'
                }}
              >
                <Typography
                  variant="bodyLargeSemibold"
                  color="#344054"
                  display={'block'}
                >
                  Bank Guarantee Utilization
                </Typography>
                <Typography
                  variant="bodyMediumMedium"
                  color="#98A2B3"
                  display="block"
                >
                  Prediction on the utilization trend of this facility
                </Typography>
              </Box>
              <Box
                sx={{
                  width: '50%',
                  flexDirection: 'row',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '1rem',
                  marginBottom: '1.5rem'
                }}
              >
                {['Bank Guarantee'].map((item, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5rem',
                        borderRadius: '0.5rem',
                        py: '0.5rem',
                        px: '0.75rem',
                        justifyContent: 'flex-end',
                        backgroundColor: 'white',
                        maxWidth: 'fit-content',
                        border: '0.0625rem solid #E5E5EF'
                      }}
                    >
                      {/* Colored Dot */}
                      <Box
                        sx={{
                          width: '0.51725rem;',
                          height: '0.51725rem;',
                          backgroundColor: index === 0 ? '#6597F9' : '#FDEAC3', // Adjust to match exact blue
                          borderRadius: '50%'
                        }}
                      />

                      {/* Text */}
                      <Typography variant="bodySmallMedium" color="#1D2939">
                        {item}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Divider
              sx={{
                color: '#F2F4F7',
                border: '.06rem solid',
                mb: '1rem'
              }}
            />

            {/* Bank Guarantee Utilization Chart */}
            <Box mt={3}>
              {isUtilLoading ? (
                <Typography>Loading Utilization Data...</Typography>
              ) : isUtilError ? (
                <Typography>Error loading utilization data</Typography>
              ) : (
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={utilizationData}>
                    <CartesianGrid strokeDasharray="0" />
                    <XAxis dataKey="month" />
                    <YAxis
                      tickFormatter={value =>
                        formatLargeAmount(value, true, false)
                      }
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#2C59B0"
                      strokeWidth={2}
                      fill="#A6C3FC"
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Box>
          </Box>

          {/* Radial Gauge */}
          <Box>
            <Grid container columnSpacing={3}>
              <Grid item xs={6}>
                {isOntimeProbLoading ? (
                  <Typography>Loading Probability Data...</Typography>
                ) : isOntimeProbError ? (
                  <Typography>Error loading on-time payment data</Typography>
                ) : (
                  <CustomGauge
                    title="On-time Payment"
                    subtitle="Probability of timely payments based on past trends."
                    gaugeValue={
                      onTimeProbabilityData
                        ? onTimeProbabilityData.likelihood
                        : 0
                    }
                    gaugeColors={['#fdf0f0', '#fbd17e', '#e8f6f1']}
                    subArcs={[{ limit: 30 }, { limit: 70 }, { limit: 100 }]}
                    icon={AiStars}
                    iconStyles={{ fill: '#F7FCFA' }}
                    ratingLabel={
                      onTimeProbabilityData
                        ? onTimeProbabilityData.category
                        : 'N/A'
                    }
                    ratingSubLabel={
                      onTimeProbabilityData
                        ? `${onTimeProbabilityData.likelihood}% likelihood of on-time payment`
                        : ''
                    }
                    labelLow="Low"
                    labelMedium="Medium"
                    labelHigh="High"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {isGuaranteeUtilizationLoading ? (
                  <Typography>Loading Probability Data...</Typography>
                ) : isGuaranteeUtilizationError ? (
                  <Typography>Error loading on-time payment data</Typography>
                ) : (
                  <CustomGauge
                    title="Bank Guarantee Utilization"
                    subtitle="Expected guarantee usage"
                    gaugeValue={
                      guaranteeUtilizationData
                        ? guaranteeUtilizationData.likelihood
                        : 0
                    }
                    gaugeColors={['#fdf0f0', '#fbd17e', '#e8f6f1']}
                    subArcs={[{ limit: 30 }, { limit: 70 }, { limit: 100 }]}
                    icon={AiStars}
                    iconStyles={{ fill: '#F7FCFA' }}
                    ratingLabel={
                      guaranteeUtilizationData
                        ? guaranteeUtilizationData.category
                        : 'N/A'
                    }
                    ratingSubLabel={
                      guaranteeUtilizationData
                        ? `${guaranteeUtilizationData.likelihood}% likelihood of on-time payment`
                        : ''
                    }
                    labelLow="Low"
                    labelMedium="Medium"
                    labelHigh="High"
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <RequestProductReceiptBottomNav
          nextButtonLabel="Next"
          isNextDisabled={false}
        />
      </Box>
    </>
  );
};
export default Analytics;
