import React, { useState } from 'react';

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedMinMaxValues
} from '@tanstack/react-table';
import type {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  Row as RowT,
  PaginationState
} from '@tanstack/react-table';

import {
  Table as MUITable,
  TableContainer as MUITableContainer,
  Box,
  Avatar,
  Button as MUIButton,
  Typography,
  Popover
} from '@mui/material';

import filterIcon from 'assets/svg/filter.svg';

import _ from 'lodash';
import { SearchBar } from 'components/search';

import { shadowsTheme } from 'themes/shadows';

import FilterModal from './filter';
import SelectedFilters from './filter/subComponents/selectedFilters';
import TableHeader from './subComponents/tableHeader';
import TableBody from './subComponents/body/tableBody';
import TableFooter from './subComponents/tableFooter';
import { Button } from 'components/button';

interface MenuItemProps<TableDataT> {
  image: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  displayText: string | ((rowData: TableDataT) => string);
  onClickMenuItem?: (rowData: TableDataT) => void;
  fill?: string;
}

interface TableProps<TableDataT> {
  columns: ColumnDef<TableDataT>[];
  data: TableDataT[];
  /**
   *  A callback for when the row is clicked, by default,
   * this will make the rows have focus styling on hover,
   *  but if optionsButton  is also set then the rows will not
   * have hover styling and instead an options button will be
   * rendered to the side of each row and made clickable
   */
  onClickRow?: (rowData: TableDataT) => void;
  filterColumns?: string[];
  hover?: boolean;
  optionsButton?: boolean;
  customOptionsIcon?: React.ReactNode;
  showSearch?: boolean;
  showFooter?: boolean;
  searchPlaceholderText?: string;
  title?: string;
  subtitle?: string;
  enableFilter: boolean;
  filterTabs?: string[];
  headerButton?: React.ReactNode;
  getRowCanExpand?: (row: RowT<TableDataT>) => boolean;
  showMenu?: boolean;
  showCaution?: boolean;
  menuItemProps?:
    | MenuItemProps<TableDataT>[]
    | ((row: RowT<TableDataT>) => MenuItemProps<TableDataT>[]);
  hideTableHead?: boolean;
  loadingTable?: boolean;
  selectedRowsClick?: (rowData: TableDataT[]) => void;
  selectedRowsActionText?: string;
  getSubRows?: (row: TableDataT) => TableDataT[];
  serverSidePagination?: PaginationState;
  serverSideRowCount?: number;
  getPage?: (page: number) => void;
  tableTitleType?: 'product-receipt-history-table';
  prefetchPages?: (page: number[]) => void;
  emptyDataIcon?: React.ReactNode;
  emptyDataTitle?: string;
  emptyDataSubtitle?: string;
  hideCheckboxes?: boolean;
}

export default function Table<TableDataT>({
  columns,
  data,
  onClickRow,
  filterColumns,
  hover,
  optionsButton,
  customOptionsIcon,
  showSearch = true,
  showFooter = true,
  hideCheckboxes = false,
  searchPlaceholderText,
  title,
  subtitle,
  enableFilter,
  headerButton,
  showMenu,
  showCaution,
  hideTableHead,
  menuItemProps,
  getRowCanExpand,
  getSubRows,
  filterTabs,
  loadingTable,
  selectedRowsClick,
  selectedRowsActionText,
  serverSidePagination,
  serverSideRowCount,
  getPage,
  prefetchPages,
  tableTitleType,
  emptyDataIcon,
  emptyDataTitle,
  emptyDataSubtitle
}: TableProps<TableDataT>) {
  const rowShouldHover = (hover || onClickRow) && !optionsButton;
  const [currentSearchText, setCurrentSearchText] = useState('');
  const [anchorFilterEl, setAnchorFilterEl] =
    useState<HTMLButtonElement | null>(null);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterPopup, setFilterPopup] = useState(false);
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowSelection, setRowSelection] = useState({});
  const showHeaders =
    title || subtitle || showSearch || enableFilter || headerButton;

  const searchFilterFn = (
    row: RowT<TableDataT>,
    columnId: string,
    value: unknown
  ) => {
    const rowVal = _.lowerCase(_.toString(row.getValue(columnId)));
    const caseRemovedValue = _.lowerCase(_.toString(value));

    if (_.startsWith(rowVal, caseRemovedValue)) {
      return true;
    } else {
      return false;
    }
  };

  const columnFilterFn = (
    row: RowT<TableDataT>,
    id: string,
    value: unknown[]
  ) => {
    const rowValue = row.getValue(id);

    if (Array.isArray(value)) {
      const stringValue =
        typeof rowValue === 'object' && rowValue !== null
          ? JSON.stringify(rowValue).toLowerCase()
          : String(rowValue).toLowerCase();

      return value.some(val =>
        stringValue.includes((val as string).toLowerCase())
      );
    }

    return false;
  };

  const table = useReactTable({
    data,
    columns,
    initialState: {
      ...(_.isUndefined(serverSidePagination) && {
        pagination: {
          pageSize: 5
        }
      })
    },
    //State
    state: {
      expanded,
      columnFilters,
      globalFilter: currentSearchText,
      rowSelection,
      ...(!_.isUndefined(serverSidePagination) && {
        pagination: serverSidePagination
      })
    },
    //Filter
    getColumnCanGlobalFilter: column => {
      if (filterColumns && _.isArray(filterColumns)) {
        return filterColumns.includes(column.id);
      }
      return false;
    },
    globalFilterFn: searchFilterFn,
    onColumnFiltersChange: setColumnFilters,
    defaultColumn: {
      filterFn: columnFilterFn
    },
    // Pipeline
    enableRowSelection: true,
    enableSubRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onExpandedChange: setExpanded,
    getSubRows: getSubRows,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    //Pagination
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: !_.isUndefined(serverSidePagination),
    rowCount: serverSideRowCount,
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand,
    enableColumnPinning: true
  });

  return (
    <Box
      sx={{
        padding: '0rem',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'stretch'
      }}
    >
      {showHeaders && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent:
              (title && headerButton) ||
              (title && (showSearch || enableFilter)) ||
              (!title && showSearch && enableFilter)
                ? 'space-between'
                : undefined,
            padding: title ? '1.5rem' : '0.75rem',
            height: '4.25rem',
            flexGrow: 1,
            maxWidth: '100%',
            backgroundColor: '#FFF',
            borderRadius: '0.75rem 0.75rem 0rem 0rem',
            border: '.063rem solid #F5F6F7',
            mb: columnFilters.length >= 1 ? '0rem' : '0.75rem'
          }}
        >
          {title && (
            <Box
              sx={{
                justifyContent: 'center',
                alignItems: 'start',
                flexWrap: 'wrap',
                flexDirection: 'column',
                display: 'flex',
                minWidth: '33%'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '1rem',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '145%',
                  letterSpacing: '-0.035rem'
                }}
                color="#354554"
              >
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '0.75rem',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '145%',
                    letterSpacing: '-0.0225rem'
                  }}
                  color="#617D99"
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}

          {showSearch && (
            <Box
              sx={{
                flexGrow: title ? '1' : undefined,
                width: !title ? '38.25rem' : '20rem'
              }}
            >
              <SearchBar
                size="sm"
                id="tableSearchBar"
                options={[]}
                onChange={value => {
                  setCurrentSearchText(value);
                }}
                placeholderText={searchPlaceholderText ?? 'Search'}
              />
            </Box>
          )}

          {enableFilter === true && (
            <MUIButton
              sx={{
                borderRadius: '0.5rem;',
                border: '0.09375em solid #EAECF0',
                backgroundColor: '#FFF',
                color: '#475467',
                display: 'flex',
                padding: '0.5rem 0.5rem 0.5rem 0.75rem',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '1rem',
                boxShadow: shadowsTheme.xxSmallShadowSoft,
                height: '2.25rem'
              }}
              disabled={data.length === 0 || !filterTabs}
              onClick={e => {
                setFilterPopup(true);
                setAnchorFilterEl(e.currentTarget);
              }}
              aria-describedby={filterPopup ? 'filter-popover' : undefined}
            >
              <Avatar
                src={filterIcon}
                sx={{ width: '1rem', height: '1rem', marginRight: '.313rem' }}
              />
              Filter
            </MUIButton>
          )}

          <Popover
            id={filterPopup ? 'filter-popover' : undefined}
            open={Boolean(anchorFilterEl)}
            anchorEl={anchorFilterEl}
            onClose={() => setAnchorFilterEl(null)}
            sx={{
              '& .MuiPopover-paper': {
                borderRadius: '.75rem',
                border: '.0625rem solid #EAECF0'
              }
            }}
            elevation={0}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <FilterModal<TableDataT>
              setAnchorFilterEl={setAnchorFilterEl}
              setFilterPopup={setFilterPopup}
              table={table}
              filterTabs={filterTabs}
            />
          </Popover>

          {headerButton && headerButton}

          {selectedRowsClick && Object.keys(rowSelection).length > 0 && (
            <Button
              size="sm"
              color="primary"
              transparent
              text={selectedRowsActionText}
              styleOverrides={{ ml: '1rem' }}
              onClick={() => {
                if (selectedRowsClick) {
                  selectedRowsClick(
                    table
                      .getSelectedRowModel()
                      .flatRows.map(row => row.original)
                  );
                }
              }}
            />
          )}
        </Box>
      )}

      <SelectedFilters
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />

      <MUITableContainer
        component={Box}
        sx={{
          borderRadius: '.075rem',
          border: '.063rem solid #F5F6F7',
          backgroundColor: '#FFF',
          width: '100%'
        }}
      >
        <MUITable>
          {!hideTableHead && (
            <TableHeader<TableDataT>
              table={table}
              optionsButton={optionsButton}
              hideCheckboxes={hideCheckboxes}
            />
          )}

          <TableBody<TableDataT>
            table={table}
            optionsButton={optionsButton}
            rowShouldHover={rowShouldHover}
            showMenu={showMenu}
            menuItemProps={menuItemProps}
            onClickRow={onClickRow}
            loading={loadingTable}
            columns={columns}
            showCaution={showCaution}
            tableTitleType={tableTitleType}
            emptyDataIcon={emptyDataIcon}
            emptyDataTitle={emptyDataTitle}
            emptyDataSubtitle={emptyDataSubtitle}
            hideCheckboxes={hideCheckboxes}
            customOptionsIcon={customOptionsIcon}
          />
        </MUITable>
      </MUITableContainer>

      {showFooter && table.getRowModel().rows.length !== 0 && (
        <TableFooter<TableDataT>
          table={table}
          getPage={getPage}
          prefetchPages={prefetchPages}
        />
      )}
    </Box>
  );
}
