import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import {
  Box,
  Divider,
  IconButton,
  Tab as MUITab,
  Tabs as MUITabs,
  Paper,
  Typography
} from '@mui/material';
import Loading from 'components/loader/loading';
import React from 'react';
import { Button } from 'components/button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetProductReceiptRequestInfoQuery } from 'services/api/productReceiptApi';
import { OrganizationType } from 'services/enums/organization';
import { useAppSelector } from 'services/hook';
import RequestDetailsTab from './tab/details';
import RequestProductReceiptTab from './tab/productReceipt';
import RequestAnalyticsTab from './tab/analytic';

const RequestSentInfoPage = () => {
  const { organizationType } = useAppSelector(state => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const { requestId, tab } = useParams();

  // Set tabs: lender gets three tabs, merchant gets two
  const tabList: string[] =
    organizationType === OrganizationType.LENDER
      ? ['details', 'product-receipt', 'analytics']
      : ['details', 'product-receipt'];

  // Display names for tabs
  const displayTabs: Record<string, string> = {
    details: 'Details',
    'product-receipt': 'Product Receipt',
    analytics: 'Analytics'
  };

  // Determine current tab index based on the URL param; default to 0 if not found
  const currentTabIndex: number = tabList.indexOf(tab ?? 'details');

  // Handler to update the URL when the tab changes
  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    // Get base path from location (e.g. '/request-sent/0666649d-2987-4990-884a-7c3b150f5c8b')
    const basePath = location.pathname.split('/').slice(0, 3).join('/');
    navigate(`${basePath}/${tabList[newValue]}`);
  };

  // Fetch request info using the new endpoint hook
  const { data, isLoading, isError } = useGetProductReceiptRequestInfoQuery({
    requestId: requestId ?? ''
  });

  if (isLoading) {
    return <Loading loading={true} loadingText="Loading request info..." />;
  }

  if (isError || !data) {
    return <div>Failed to load request info</div>;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        py: 0,
        backgroundColor: '#F9FAFB',
        width: '100%',
        top: '0',
        left: '0',
        zIndex: 2,
        position: 'absolute'
      }}
    >
      {/* Header */}
      <Box
        sx={{
          padding: '2rem',
          width: '100%',
          height: '3.125rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '.313rem',
          backgroundColor: '#ffffff'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '.313rem'
          }}
        >
          <IconButton
            size="medium"
            sx={{ color: '#344054', mr: 1 }}
            onClick={() => navigate('/request-sent')}
          >
            <ArrowBackIosRoundedIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography variant="h7Bold" color={'#475467'}>
              {organizationType === OrganizationType.LENDER
                ? data.merchant.name
                : data.lender.name}
            </Typography>
            <Typography variant="bodyMediumMedium" color="#98A2B3">
              Product receipts requests sent to merchants
            </Typography>
          </Box>
        </Box>

        <Box>
          {organizationType === OrganizationType.MERCHANT && (
            <Button
              color="primary"
              size="md"
              text={'Create Product Receipt'}
              onClick={() => {
                navigate(
                  `/product-receipts/create?requestId=${data.request_id}`
                );
              }}
            />
          )}
        </Box>
      </Box>

      <Divider sx={{ color: '#F5F6F7', border: '.06rem solid' }} />

      {/* Tabs */}
      <MUITabs
        value={Math.max(0, currentTabIndex)}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: '#F5F6F7',
          display: 'flex',
          justifyContent: 'start',
          px: 2,
          background: '#fff'
        }}
      >
        {tabList.map(label => (
          <MUITab
            key={label}
            label={
              <Typography variant="bodyMediumMedium">
                {displayTabs[label]}
              </Typography>
            }
          />
        ))}
      </MUITabs>

      {/* Tab Panels */}
      <Paper
        elevation={0}
        sx={{
          padding: '1.25rem',
          borderRadius: '0.75rem',
          backgroundColor: '#FFF',
          mt: 2,
          mx: 2,
          mb: '18.75rem.'
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '0.75rem',
            border: '0.0625rem solid #F2F4F7',
            background: '#F9FAFB'
          }}
        >
          {tabList.map((tabName, index) => (
            <Box
              key={tabName}
              role="tabpanel"
              hidden={currentTabIndex !== index}
              sx={{ width: '100%' }}
            >
              {tabName === 'details' && <RequestDetailsTab data={data} />}
              {tabName === 'product-receipt' && (
                <RequestProductReceiptTab data={data} />
              )}
              {tabName === 'analytics' &&
                organizationType === OrganizationType.LENDER && (
                  <RequestAnalyticsTab data={data} />
                )}
            </Box>
          ))}
        </Box>
      </Paper>
    </Box>
  );
};
export default RequestSentInfoPage;
