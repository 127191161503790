import { Chip } from 'components/chip';
import { ReactComponent as LocationIcon } from 'assets/custom-svg/location.svg';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { ProductReceiptRequestInfoApiData } from 'services/types/productReceiptRequest';
import _ from 'lodash';
import { formattedDate, formattedNumber } from 'utilities/helperFunc/formatter';

interface TabProps {
  data: ProductReceiptRequestInfoApiData;
}

const RequestProductReceiptTab: React.FC<TabProps> = ({ data }) => {
  return (
    <Box
      sx={{
        width: '37.3rem',
        maxWidth: '80rem',
        margin: '0 auto',
        borderRadius: '0.75rem',
        backgroundColor: 'white',
        my: '1.75rem',
        boxShadow: '0px 1.5px 4px -1px rgba(113, 120, 132, 0.05)',
        border: '0.0625rem solid rgba(250, 251, 252, 0.99)',
        mb: '5.5rem'
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: '1rem',
          display: 'flex',
          justifyContent: 'space-between',
          mb: '0.69rem'
        }}
      >
        <LocationIcon fill={'#ffff'} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.69rem'
          }}
        >
          <Chip label={_.capitalize(data.status)} size="sm" color="warning" />
          <Chip
            label={`Sent: ${formattedDate(data.created_on, 'MMM Do, YYYY')}`}
            size="sm"
            icon
          />
        </Box>
      </Box>

      {/* PRC's */}
      <Box
        sx={{
          p: '1rem',
          py: '0rem'
        }}
      >
        {data.product_receipt_data.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                background: 'rgba(250, 251, 252, 0.99)',
                mb: '0.69rem',
                px: '1rem'
              }}
            >
              <Box
                sx={{
                  py: '0.75rem'
                }}
              >
                <Typography
                  variant="bodyMediumSemibold"
                  color="#475467"
                  sx={{}}
                >
                  Product Receipt {index + 1}
                </Typography>
              </Box>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #F9FAFB',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Amount
                </Typography>
                <Typography variant="bodyLargeSemibold" color="#475467">
                  ₦{' '}
                  {Number(data.total_request_price) *
                    Number(item.total_quantity)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid ##F9FAFB',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Quantity
                </Typography>
                <Typography variant="bodyLargeSemibold" color="#475467">
                  {formattedNumber(item.total_quantity)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                width="inherit"
                justifyContent="space-between"
                sx={{
                  borderBottom: '.063rem solid #F9FAFB',
                  padding: '1rem 0rem'
                }}
              >
                <Typography variant="bodyMediumMedium" color="#667085">
                  Date for repayment
                </Typography>
                <Typography variant="bodyLargeSemibold" color="#475467">
                  31st Aug, 2024
                </Typography>
              </Stack>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default RequestProductReceiptTab;
