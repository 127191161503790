import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as PrcEditIcon } from 'assets/svg/prcEdit.svg';
import { ReactComponent as PrcDeleteIcon } from 'assets/svg/prcDelete.svg';
import { Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import { ReactComponent as ChevronDown } from 'assets/svg/chevron-down.svg';
import { ReactComponent as ChevronUp } from 'assets/svg/chevron-up.svg';
import { ReactComponent as PlusIcon } from 'assets/svg/plus.svg';
import { ReactComponent as TagItemIcon } from 'assets/svg/tag.svg';
import { ReactComponent as TagIcon } from 'assets/svg/tagIcon.svg';
import { Accordion } from 'components/accordion';
import { Button } from 'components/button';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect,
  FormSelectOption
} from 'components/form';
import MultiPurposeInput from 'components/form/formMultiPurposeInput';
import { SvgWrapper } from 'components/svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { ProductApiData, ProductApiVariantsData } from 'services/types/product';
import {
  FormValues as CommonFormValues,
  CreateProductReceiptContext,
  ProductReceiptIssueType,
  RequestDetails
} from '../../context/createProductReceiptContext';
import CreateProductReceiptBottomNav from '../../nav/bottomNav';
import { BatchCreateProductReceiptValidator } from '../../validator/createProductReceiptValidator';
import { useGetProductReceiptRequestInfoQuery } from 'services/api/productReceiptApi';

// For batch mode, we extend the common form values to use arrays for quantity and expiry_date.
interface BatchFormValues
  extends Omit<CommonFormValues, 'quantity' | 'expiry_date'> {
  quantity: string[];
  expiry_date: string[];
}

type BatchPRCProps = {
  request: RequestDetails | null;
  bankOptions: { title: string; value: string }[];
  customerOptions: { title: string; value: string }[];
  productData: ProductApiData[] | undefined;
};

const BatchProductReceiptDetails = ({
  request,
  bankOptions,
  customerOptions,
  productData
}: BatchPRCProps) => {
  const { data, updateFormData, setFormState, selectedIssueType } = useContext(
    CreateProductReceiptContext
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [prcList, setPrcList] = useState<number[]>([0]);

  const { data: requestInfo } = useGetProductReceiptRequestInfoQuery(
    { requestId: request?.id || '' },
    { skip: !request?.id }
  );

  // If a request ID exists, open the primary details accordion by default.
  useEffect(() => {
    if (request?.id) {
      setIsOpen(true);
    }
  }, [request]);

  // Convert the context’s common fields into arrays.
  const defaultValues: BatchFormValues = {
    customer_name: data.customer_name,
    bank: data.bank,
    product: data.product,
    product_variant: data.product_variant,
    unit: data.unit,
    amount: data.amount,
    quantity: Array.isArray(data.quantity)
      ? data.quantity
      : [data.quantity || ''],
    expiry_date: Array.isArray(data.expiry_date)
      ? data.expiry_date
      : [data.expiry_date || ''],
    tags: data.tags,
    tagsType: data.tagsType,
    tagsName: data.tagsName
  };

  const {
    register,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { isValid, touchedFields }
  } = useForm<BatchFormValues>({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(
      BatchCreateProductReceiptValidator(selectedIssueType)
    ) as unknown as Resolver<BatchFormValues>
  });

  const values = watch();
  const selectedProduct = watch('product');
  const selectedProductVariant = watch('product_variant');
  const tagsType = watch('tagsType');
  const tagsName = watch('tagsName');

  const selectedProductObj = productData?.find(
    product => product.product_id === selectedProduct
  );
  const selectedVariantObj = selectedProductObj?.variants?.find(
    (variant: ProductApiVariantsData) =>
      variant.product_variant_id === selectedProductVariant
  );

  const productOptions = useMemo((): FormSelectOption[] => {
    return (
      productData?.map(product => ({
        title: product.name,
        value: product.product_id
      })) || []
    );
  }, [productData]);

  const productVariantOptions = useMemo((): FormSelectOption[] => {
    if (selectedProductObj && selectedProductObj.variants?.length) {
      return selectedProductObj.variants.map(variant => ({
        title: `${selectedProductObj.name} ${variant.currency}`,
        value: variant.product_variant_id
      }));
    }
    return [];
  }, [selectedProductObj]);

  // Update dependent fields when product or variant changes.
  useEffect(() => {
    if (selectedProductObj) {
      setValue('unit', selectedVariantObj?.unit || '');
      setValue('amount', selectedVariantObj?.price || '');
    }
    if (
      !selectedProductVariant &&
      (touchedFields.unit || touchedFields.amount)
    ) {
      setError('unit', {
        type: 'manual',
        message: 'Please select a product option first.'
      });
      setError('amount', {
        type: 'manual',
        message: 'Please select a product option first.'
      });
    } else {
      clearErrors('unit');
      clearErrors('amount');
    }
    if (touchedFields.product_variant) {
      trigger('product_variant');
    }
  }, [
    selectedProductVariant,
    selectedProductObj,
    touchedFields,
    setValue,
    setError,
    clearErrors,
    trigger
  ]);

  // Update context with common fields (including the batch arrays) whenever they change.
  useEffect(() => {
    updateFormData({
      customer_name: values.customer_name,
      bank: values.bank,
      product: values.product,
      product_variant: values.product_variant,
      unit: values.unit,
      amount: values.amount,
      quantity: values.quantity,
      expiry_date: values.expiry_date,
      tags: values.tags,
      tagsType: values.tagsType,
      tagsName: values.tagsName
    });
    setFormState(isValid);
  }, [values, isValid, updateFormData, setFormState]);

  // Auto-populate the form when the requestInfo data becomes available.
  useEffect(() => {
    if (requestInfo) {
      // Update common fields based on the API response.
      setValue('customer_name', requestInfo.customer?.name || '');
      // Here, we use lender.name to fill the bank field
      setValue('bank', requestInfo.lender?.name || '');
      setValue('product', requestInfo.product?.name || '');
      setValue(
        'product_variant',
        requestInfo.product?.product_variant_id || ''
      );
      setValue('unit', requestInfo.product?.unit || '');
      // Using the quoted_request_price as the amount
      setValue('amount', requestInfo.quoted_request_price || '');

      // Map each product_receipt_data item to the batch list.
      if (requestInfo.product_receipt_data?.length) {
        const quantities = requestInfo.product_receipt_data.map(item =>
          item.total_quantity.toString()
        );
        setValue('quantity', quantities);
        // If expiry dates are provided by your API, set them here;
        // otherwise, leave them empty or set defaults.
        const expiryDates = requestInfo.product_receipt_data.map(() => '');
        setValue('expiry_date', expiryDates);

        // Optionally, set tags from the first receipt data item.
        if (requestInfo.product_receipt_data[0].tags) {
          const mappedTags = requestInfo.product_receipt_data[0].tags.map(
            tag => ({
              tagsType: tag.key,
              tagsName: tag.value
            })
          );
          setValue('tags', mappedTags);
        }

        // Set the product receipt list state to match the number of entries.
        setPrcList(
          Array.from(
            { length: requestInfo.product_receipt_data.length },
            (_, i) => i
          )
        );
      }
    }
  }, [requestInfo, setValue]);

  const batchFormIsComplete =
    Boolean(values.customer_name) &&
    (selectedIssueType === ProductReceiptIssueType.DIRECT ||
      Boolean(values.bank)) &&
    Boolean(values.product) &&
    Boolean(values.product_variant) &&
    Boolean(values.unit) &&
    Boolean(values.amount) &&
    Array.isArray(values.quantity) &&
    values.quantity.every(q => Boolean(q)) &&
    Array.isArray(values.expiry_date) &&
    values.expiry_date.every(date => Boolean(date)) &&
    Array.isArray(values.tags) &&
    values.tags.length >= 1;

  const [showAddTagButton, setShowAddTagButton] = useState(false);
  const [showCreateTagButton, setShowCreateTagButton] = useState(false);

  useEffect(() => {
    register('tags', { value: [] });
  }, [register]);

  const handleAddTag = () => {
    const newTag = { tagsType, tagsName };
    setValue('tags', [...(values.tags || []), newTag], {
      shouldValidate: true
    });
    setValue('tagsType', '');
    setValue('tagsName', '');
  };

  const handleCreateTag = () => {
    const newTag = { tagsType, tagsName };
    setValue('tags', [...(values.tags || []), newTag]);
    setValue('tagsType', '');
    setValue('tagsName', '');
  };

  const tagsTypeOptions = [
    { value: 'tag1', label: 'Transaction ID_1' },
    { value: 'tag2', label: 'Transaction ID_2' }
  ];

  const handleEditTag = (index: number) => {
    const tagToEdit = values.tags[index];
    setValue('tagsType', tagToEdit.tagsType);
    setValue('tagsName', tagToEdit.tagsName);
  };

  const handleDeleteTag = (index: number) => {
    const newTags = [...(values.tags || [])];
    newTags.splice(index, 1);
    setValue('tags', newTags);
  };

  return (
    <>
      <Box
        sx={{
          width: '56.25rem',
          marginBottom: '6.25rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0.0625rem solid #EAECF0',
          borderRadius: '0.5rem'
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            margin: 0,
            padding: 0,
            py: 0
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              borderRight: '0.12rem solid #EAECF0'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: 'auto',
                mt: '1.5rem',
                borderRadius: '.75rem 0 0 .75rem',
                background: '#fff'
              }}
            >
              {/* Common Fields Section */}
              <Box
                sx={{
                  border: '0.063rem solid #F5F6F7',
                  borderRadius: '.75rem',
                  background: '#fff',
                  mb: '1.12rem'
                }}
              >
                <Stack
                  sx={{
                    p: '1rem',
                    pt: '1.25rem',
                    borderBottom: '.063rem solid #F5F6F7',
                    gap: '.25rem'
                  }}
                >
                  <Typography variant="h6Bold" color="#475467">
                    Batch Product Receipt
                  </Typography>
                  <Typography variant="bodyMediumRegular" color="#667085">
                    Enter common details
                  </Typography>
                </Stack>

                <Box sx={{ p: '1rem .75rem' }}>
                  {request !== null && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      gap=".75rem"
                      sx={{
                        borderRadius: '0.5rem',
                        border: '.063rem solid #F2F4F7',
                        mb: '1rem',
                        p: '.75rem 0rem'
                      }}
                    >
                      <Typography variant="bodyMediumSemibold" color="#344054">
                        This product receipt is created by request
                      </Typography>
                      <Typography variant="bodySmallSemibold" color="#667085">
                        Product Receipt was requested by{' '}
                        {requestInfo?.lender.name} on behalf of{' '}
                        {requestInfo?.customer.name}
                      </Typography>
                    </Stack>
                  )}

                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    padding={isOpen ? '1rem 0rem' : '0rem'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <Typography variant="bodyMediumSemibold" color="#667085">
                      Primary Details
                    </Typography>
                    <SvgWrapper
                      icon={isOpen ? ChevronUp : ChevronDown}
                      width="1.25rem"
                      height="1.25rem"
                      styleOverrides={{
                        fill: 'none',
                        stroke: '#667085'
                      }}
                    />
                  </Stack>

                  {isOpen ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        p: '1.25rem',
                        borderRadius: '0.5rem',
                        border: '0.0625rem solid #F5F6F7',
                        background: 'rgba(102, 112, 133, 0.02)',
                        pb: '0rem'
                      }}
                    >
                      <Stack sx={{ width: '100%' }}>
                        <FormAutocomplete<BatchFormValues>
                          control={control}
                          multiple={false}
                          name="customer_name"
                          label="CUSTOMER NAME"
                          options={customerOptions.map(option => option.title)}
                        />

                        <FormAutocomplete<BatchFormValues>
                          control={control}
                          multiple={false}
                          name="bank"
                          label="INTERMEDIARY"
                          options={bankOptions.map(option => option.title)}
                        />

                        <FormSelect<BatchFormValues>
                          name="product"
                          options={productOptions}
                          label="PRODUCT"
                          control={control}
                          renderSelected={(value: string) => value}
                        />

                        <FormSelect<BatchFormValues>
                          name="product_variant"
                          options={productVariantOptions}
                          label="PRODUCT OPTION"
                          control={control}
                          renderSelected={(value: string) => value}
                        />

                        <Stack direction="row" gap=".75rem">
                          <FormInput<BatchFormValues>
                            control={control}
                            name="unit"
                            label="UNIT"
                            readonly={true}
                          />
                          <FormInput<BatchFormValues>
                            control={control}
                            name="amount"
                            label="PRICE"
                            readonly={true}
                          />
                        </Stack>
                      </Stack>
                    </Box>
                  ) : null}
                </Box>
              </Box>

              {/* Batch Entries Section */}
              <Box
                sx={{
                  border: '0.063rem solid #F5F6F7',
                  borderRadius: '.75rem',
                  background: '#fff',
                  width: '29rem',
                  mb: '3rem',
                  p: '1rem'
                }}
              >
                <Box sx={{ maxHeight: '25rem', overflowY: 'auto' }}>
                  {prcList.map((_, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        borderRadius: '0.5rem',
                        border: '0.0625rem solid #F5F6F7',
                        background: 'rgba(102, 112, 133, 0.02)',
                        pb: '0rem',
                        mb: '1rem'
                      }}
                    >
                      <Accordion
                        summary={
                          <Stack
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              borderRadius: '0.75rem'
                            }}
                          >
                            <Typography
                              variant="bodyMediumSemibold"
                              color="#475467"
                            >
                              Product Receipt {index + 1}
                            </Typography>
                          </Stack>
                        }
                      >
                        <Stack sx={{ mt: '1rem' }} gap="1rem">
                          <FormInput<BatchFormValues>
                            control={control}
                            name={`quantity.${index}`}
                            label="QUANTITY"
                          />
                          <FormDateInput<BatchFormValues>
                            control={control}
                            name={`expiry_date.${index}`}
                            label="EXPIRY DATE"
                            format="YYYY-MM-DD"
                            disablePast
                          />
                        </Stack>
                      </Accordion>
                    </Box>
                  ))}
                </Box>

                <Box
                  sx={{
                    p: '.75rem 0rem',
                    borderTop: '.063rem solid #F5F6F7',
                    zIndex: 2
                  }}
                >
                  <Button
                    styleOverrides={{ marginLeft: 'auto', border: 'none' }}
                    text="Add Product Receipt"
                    transparent
                    color="primary"
                    size="md"
                    icon={PlusIcon}
                    iconPosition="start"
                    onClick={() =>
                      setPrcList(prevList => [...prevList, prevList.length])
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5} sx={{}}>
            <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: '0px',
                background: 'rgba(250, 251, 252, 0.99)',
                height: '100%',
                px: 1.5,
                pt: 2
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                <TagIcon width="44px" height="44px" fill="#ffffff" />
              </Stack>
              <Box>
                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    fontWeight: 600,
                    display: 'block'
                  }}
                  color="#344054"
                >
                  Add/Create Tags
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Tags help categorize & organize Product Receipts.
                  <br /> Create or select one to tag this receipt.
                </Typography>
              </Box>

              <MultiPurposeInput
                name="tagsType"
                label="TAG TYPE"
                control={control}
                type="select"
                options={tagsTypeOptions}
                placeholder="Select or enter text"
                onOpenMenu={() => {
                  setShowCreateTagButton(false);
                }}
                onSelect={value => {
                  // Optionally update the tag name based on the selected option
                  const selectedLabel =
                    tagsTypeOptions.find(opt => opt.value === value)?.label ||
                    '';
                  setValue('tagsName', selectedLabel);
                  // When an option is selected, show the Add Tag button
                  setShowAddTagButton(true);
                  setShowCreateTagButton(false);
                }}
                // When the user types manually in the tagType field:
                onChange={value => {
                  if (value.trim() !== '') {
                    // Show Create Tag button if the user is typing
                    setShowCreateTagButton(true);
                    setShowAddTagButton(false);
                  } else {
                    // If field is cleared, hide Create Tag button (or adjust as needed)
                    setShowCreateTagButton(false);
                  }
                }}
                size="medium"
              />

              <MultiPurposeInput
                name="tagsName"
                label="TAG NAME"
                control={control}
                type="text"
                options={[
                  { value: 'tag1', label: 'Transaction ID_1' },
                  { value: 'tag2', label: 'Transaction ID_2' }
                ]}
                placeholder="Enter tag name"
                onChange={value => {
                  // When the user types, hide the Add Tag button and show Create Tag if there is text
                  if (value.trim() !== '') {
                    setShowCreateTagButton(true);
                    setShowAddTagButton(false);
                  } else {
                    setShowCreateTagButton(false);
                  }
                }}
              />

              {showAddTagButton && (
                <>
                  <Button
                    onClick={handleAddTag}
                    color={'primary'}
                    size="md"
                    outline
                    text=" Add Tag"
                    width="100%"
                    styleOverrides={{
                      mr: '0.5rem',
                      boxShadow: '0px 1.5px 4px -1px rgba(113, 120, 132, 0.5)'
                    }}
                  />
                </>
              )}

              {showCreateTagButton && (
                <Button
                  onClick={handleCreateTag}
                  color={'primary'}
                  size="md"
                  outline
                  text="Create Tag"
                  width="100%"
                  styleOverrides={{
                    mr: '0.5rem',
                    boxShadow: '0px 1.5px 4px -1px rgba(113, 120, 132, 0.5)'
                  }}
                />
              )}

              {/* rendered the tags list */}
              {values.tags?.length > 0 && (
                <Stack spacing={2} mt={2}>
                  {values.tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#F9FAFB',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0.75rem'
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          key={index}
                          sx={{
                            background: '#F5F6F7',
                            borderRadius: '0.375rem',
                            height: '2.25rem',
                            display: 'flex',
                            alignItem: 'center',
                            p: '0.5rem'
                          }}
                        >
                          <Box
                            mr={'0.3125rem'}
                            sx={{
                              display: 'inline'
                            }}
                          >
                            <SvgWrapper
                              icon={TagItemIcon}
                              width=".75rem"
                              height=".75rem"
                              color="##5B5DE3"
                            />
                          </Box>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {`${tag.tagsType} : `}
                          </Typography>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {tag.tagsName}
                          </Typography>
                        </Box>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <IconButton onClick={() => handleEditTag(index)}>
                          <PrcEditIcon
                            stroke="#667085"
                            height="1.25rem"
                            width="1.25rem"
                          />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteTag(index)}>
                          <PrcDeleteIcon fill="#667085" />
                        </IconButton>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%'
        }}
      >
        <CreateProductReceiptBottomNav
          nextButtonLabel="Next"
          isNextDisabled={!batchFormIsComplete}
        />
      </Box>
    </>
  );
};

export default BatchProductReceiptDetails;
