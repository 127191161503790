import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactComponent as TagItemIcon } from 'assets/svg/tag.svg';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { ReactComponent as InfoIcon } from 'assets/svg/infoPrimary.svg';
import { ReactComponent as TagIcon } from 'assets/svg/tagIcon.svg';
import { ReactComponent as EditIcon } from 'assets/svg/prcEdit.svg';
import { ReactComponent as DeleteIcon } from 'assets/svg/prcDelete.svg';
import {
  FormAutocomplete,
  FormDateInput,
  FormInput,
  FormSelect,
  FormSelectOption
} from 'components/form';
import MultiPurposeInput from 'components/form/formMultiPurposeInput';
import { SvgWrapper } from 'components/svg';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { ProductApiData, ProductApiVariantsData } from 'services/types/product';
import {
  CreateProductReceiptContext,
  FormValues,
  ProductReceiptIssueType,
  RequestDetails
} from '../../context/createProductReceiptContext';
import CreateProductReceiptBottomNav from '../../nav/bottomNav';
import { createProductReceiptValidator } from '../../validator/createProductReceiptValidator';

type SinglePRCProps = {
  request: RequestDetails | null;
  bankOptions: { title: string; value: string }[];
  customerOptions: { title: string; value: string }[];
  productData: ProductApiData[] | undefined;
};

const SingleProductReceiptDetails = ({
  request,
  bankOptions,
  customerOptions,
  productData
}: SinglePRCProps) => {
  const { data, updateFormData, setFormState, selectedIssueType } = useContext(
    CreateProductReceiptContext
  );

  const {
    register,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: { isValid, touchedFields }
  } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      customer_name: data.customer_name,
      bank: data.bank,
      product: data.product,
      product_variant: data.product_variant,
      unit: data.unit,
      quantity: data.quantity,
      amount: data.amount,
      expiry_date: data.expiry_date,
      tags: data.tags || [],
      tagsType: data.tagsType,
      tagsName: data.tagsName
    },
    resolver: yupResolver(
      createProductReceiptValidator(selectedIssueType)
    ) as unknown as Resolver<FormValues>
  });

  const values = watch();
  const selectedProduct = watch('product');
  const selectedProductVariant = watch('product_variant');
  const tagsType = watch('tagsType');
  const tagsName = watch('tagsName');

  const formIsComplete =
    Boolean(values.customer_name) &&
    (selectedIssueType === ProductReceiptIssueType.DIRECT ||
      Boolean(values.bank)) &&
    Boolean(values.product) &&
    Boolean(values.product_variant) &&
    Boolean(values.quantity) &&
    Boolean(values.unit) &&
    Boolean(values.amount) &&
    Boolean(values.expiry_date) &&
    Array.isArray(values.tags) &&
    values.tags.length >= 1;

  const [showAddTagButton, setShowAddTagButton] = useState(false);
  const [showCreateTagButton, setShowCreateTagButton] = useState(false);

  const selectedProductObj = productData?.find(
    product => product.product_id === selectedProduct
  );

  const selectedVariantObj = selectedProductObj?.variants?.find(
    (variant: ProductApiVariantsData) =>
      variant.product_variant_id === selectedProductVariant
  );

  const productOptions = useMemo((): FormSelectOption[] => {
    return (
      productData?.map(product => ({
        title: product.name,
        value: product.product_id
      })) || []
    );
  }, [productData]);

  const productVariantOptions = useMemo((): FormSelectOption[] => {
    if (selectedProductObj && selectedProductObj.variants?.length) {
      return selectedProductObj.variants.map(variant => ({
        title: `${selectedProductObj.name} ${variant.currency}`,
        value: variant.product_variant_id
      }));
    }
    return [];
  }, [selectedProductObj]);

  useEffect(() => {
    updateFormData({
      customer_name: values.customer_name,
      bank: values.bank,
      product: values.product,
      product_variant: values.product_variant,
      quantity:
        typeof values.quantity === 'string'
          ? values.quantity.replace(/,/g, '')
          : values.quantity,
      unit: values.unit,
      amount: values.amount.replace(/[,₦]/g, ''),
      expiry_date: values.expiry_date,
      tags: values.tags || [],
      tagsType: values.tagsType,
      tagsName: values.tagsName
    });
    setFormState(isValid);
  }, [values, isValid, updateFormData, setFormState]);

  useEffect(() => {
    if (selectedProductObj) {
      setValue('unit', selectedVariantObj?.unit || '');
      setValue('amount', selectedVariantObj?.price || '');
    }
    if (
      !selectedProductVariant &&
      (touchedFields.unit || touchedFields.amount)
    ) {
      setError('unit', {
        type: 'manual',
        message: 'Please select a product option first.'
      });
      setError('amount', {
        type: 'manual',
        message: 'Please select a product option first.'
      });
    } else {
      clearErrors('unit');
      clearErrors('amount');
    }
    if (touchedFields.product_variant) {
      trigger('product_variant');
    }
  }, [
    selectedProductVariant,
    selectedProductObj,
    touchedFields.unit,
    touchedFields.amount,
    touchedFields.product_variant,
    setValue,
    setError,
    clearErrors,
    trigger
  ]);

  useEffect(() => {
    register('tags', { value: [] });
  }, [register]);

  const handleAddTag = () => {
    const newTag = { tagsType, tagsName };
    setValue('tags', [...(values.tags || []), newTag], {
      shouldValidate: true
    });
    setValue('tagsType', '');
    setValue('tagsName', '');
    setShowAddTagButton(false);
    setShowCreateTagButton(false);
  };

  const handleCreateTag = () => {
    const newTag = { tagsType, tagsName };
    setValue('tags', [...(values.tags || []), newTag]);
    setValue('tagsType', '');
    setValue('tagsName', '');
    setShowAddTagButton(false);
    setShowCreateTagButton(false);
  };

  const tagsTypeOptions = [
    { value: 'tag1', label: 'Transaction ID_1' },
    { value: 'tag2', label: 'Transaction ID_2' }
  ];

  const handleEditTag = (index: number) => {
    const tagToEdit = values.tags[index];
    setValue('tagsType', tagToEdit.tagsType);
    setValue('tagsName', tagToEdit.tagsName);
  };

  const handleDeleteTag = (index: number) => {
    const newTags = [...(values.tags || [])];
    newTags.splice(index, 1);
    setValue('tags', newTags);
  };

  return (
    <>
      <Box
        sx={{
          width: '56.25rem',
          marginBottom: '6.25',
          marginLeft: 'auto',
          marginRight: 'auto',
          border: '0.0625rem solid #EAECF0',
          borderRadius: '0.5rem'
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            margin: 0,
            padding: 0,
            py: 0
          }}
        >
          <Grid
            item
            xs={7}
            sx={{
              borderRight: '0.125rem solid #EAECF0'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: 'auto',
                borderRadius: '.75rem 0 0 .75rem',
                background: '#fff'
              }}
            >
              <Stack
                sx={{
                  padding: '1rem',
                  paddingTop: '1.25rem',
                  borderBottom: '.063rem solid #F5F6F7',
                  gap: '.25rem'
                }}
              >
                <Typography variant="h6Bold" color="#475467">
                  Single Product Receipt
                </Typography>
                <Typography variant="bodyMediumRegular" color="#667085">
                  Enter details
                </Typography>
              </Stack>

              <Box padding=".75rem">
                {request !== null && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap=".75rem"
                    sx={{
                      borderRadius: '0.5rem',
                      border: '.063rem solid #F2F4F7',
                      mb: '1rem',
                      padding: '.75rem 0rem'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '1.5rem',
                        width: '1.5rem',
                        borderRadius: '.5rem',
                        backgroundColor: '#ECF2FE',
                        padding: '0.375rem'
                      }}
                    >
                      <SvgWrapper
                        icon={InfoIcon}
                        width=".75rem"
                        height=".75rem"
                        color="#3E7DF8"
                      />
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <Typography variant="bodySmallSemibold" color="#344054">
                        This product receipt is created by request
                      </Typography>
                      <Typography variant="bodySmallSemibold" color="#667085">
                        Product Receipt was requested by Globus bank on behalf
                        of Hamalaya Group
                      </Typography>
                    </Box>
                  </Stack>
                )}

                <Box
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  sx={{
                    borderRadius: '0.5rem',
                    border: '0.0625rem solid #F5F6F7',
                    background: 'rgba(102, 112, 133, 0.02)',
                    pb: '0rem',
                    maxHeight: { md: '28rem', lg: '35rem', xl: 'fit-content' },
                    overflow: 'auto'
                  }}
                >
                  <Stack direction="column" width="inherit">
                    <Stack direction="column" padding=".75rem" pb="0rem">
                      <FormAutocomplete<FormValues>
                        control={control}
                        multiple={false}
                        name="customer_name"
                        label={
                          selectedIssueType ===
                          ProductReceiptIssueType.INTERMEDIARY
                            ? 'CUSTOMER'
                            : 'DISTRIBUTOR'
                        }
                        options={customerOptions.map(option => option.title)}
                      />

                      {selectedIssueType ===
                        ProductReceiptIssueType.INTERMEDIARY && (
                        <FormAutocomplete<FormValues>
                          control={control}
                          multiple={false}
                          name="bank"
                          label="BANK"
                          options={bankOptions.map(option => option.title)}
                        />
                      )}

                      <FormDateInput<FormValues>
                        control={control}
                        name="expiry_date"
                        label="EXPIRY DATE"
                        format="YYYY-MM-DD"
                        disablePast
                      />
                    </Stack>

                    <Box padding=".75rem 1.25rem" pt="0rem" width="inherit">
                      <Typography variant="bodyLargeSemibold" color="#667085">
                        Product Information
                      </Typography>
                    </Box>

                    <Divider
                      sx={{
                        color: '#F2F4F7',
                        border: '.06rem solid',
                        mb: '1.5rem'
                      }}
                    />

                    <Stack padding="0rem .75rem">
                      <FormSelect<FormValues>
                        name="product"
                        options={productOptions}
                        label="PRODUCT"
                        control={control}
                        renderSelected={(value: string) => value}
                      />

                      <FormSelect<FormValues>
                        name="product_variant"
                        options={productVariantOptions}
                        label="PRODUCT OPTION"
                        control={control}
                        renderSelected={(value: string) => value}
                      />

                      <Stack direction="row" gap=".75rem">
                        <FormInput<FormValues>
                          control={control}
                          name="unit"
                          label="UNIT"
                          readonly={true}
                        />
                        <FormInput<FormValues>
                          control={control}
                          name="amount"
                          label="PRICE"
                          readonly={true}
                        />
                      </Stack>

                      <FormInput<FormValues>
                        control={control}
                        name="quantity"
                        label="QUANTITY"
                      />
                    </Stack>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '100%',
                marginLeft: '0rem',
                background: 'rgba(250, 251, 252, 0.99)',
                height: '100%',
                px: 1.5,
                pt: 2
              }}
            >
              <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
                <TagIcon fill="#ffffff" />
              </Stack>
              <Box>
                <Typography
                  variant="bodyMediumMedium"
                  sx={{
                    fontWeight: 600,
                    display: 'block'
                  }}
                  color="#344054"
                >
                  Add/Create Tags
                </Typography>
                <Typography variant="bodyMediumMedium" color="#98A2B3">
                  Tags help categorize & organize Product Receipts.
                  <br /> Create or select one to tag this receipt.
                </Typography>
              </Box>

              <MultiPurposeInput
                name="tagsType"
                label="TAG TYPE"
                control={control}
                type="select"
                options={tagsTypeOptions}
                placeholder="Select or enter text"
                onOpenMenu={() => {
                  setShowCreateTagButton(false);
                }}
                onSelect={value => {
                  // Optionally update the tag name based on the selected option
                  const selectedLabel =
                    tagsTypeOptions.find(opt => opt.value === value)?.label ||
                    '';
                  setValue('tagsName', selectedLabel);
                  // When an option is selected, show the Add Tag button
                  setShowAddTagButton(true);
                  setShowCreateTagButton(false);
                }}
                // When the user types manually in the tagType field:
                onChange={value => {
                  if (value.trim() !== '') {
                    setShowCreateTagButton(true);
                    setShowAddTagButton(false);
                  } else {
                    setShowCreateTagButton(false);
                  }
                }}
                size="medium"
              />

              <MultiPurposeInput
                name="tagsName"
                label="TAG NAME"
                control={control}
                type="text"
                options={[
                  { value: 'tag1', label: 'Transaction ID_1' },
                  { value: 'tag2', label: 'Transaction ID_2' }
                ]}
                placeholder="Enter tag name"
                onChange={value => {
                  // When the user types, hide the Add Tag button and show Create Tag if there is text
                  if (value.trim() !== '') {
                    setShowCreateTagButton(true);
                    setShowAddTagButton(false);
                  } else {
                    setShowCreateTagButton(false);
                  }
                }}
              />

              {showAddTagButton && (
                <>
                  <Button
                    onClick={handleAddTag}
                    color="primary"
                    fullWidth
                    sx={{
                      height: '2.75rem',
                      borderRadius: '0.5rem',
                      border: '0.0625rem solid #C3D7FD',
                      color: '#3A76F0',
                      fontSize: '1rem',
                      fontWeight: '600',
                      textAlign: 'center',
                      textTransform: 'none',
                      justifyContent: 'center',
                      padding: '0.75rem',
                      boxShadow:
                        '0rem 0.09375rem 0.25rem -0.0625rem rgba(113, 120, 132, 0.5)'
                    }}
                  >
                    Add Tag
                  </Button>
                </>
              )}
              {showCreateTagButton && (
                <Button
                  onClick={handleCreateTag}
                  color="primary"
                  fullWidth
                  sx={{
                    height: '2.75rem',
                    borderRadius: '0.5rem',
                    border: '0.0625rem solid #C3D7FD',
                    color: '#3A76F0',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    textTransform: 'none',
                    justifyContent: 'center',
                    padding: '0.75rem',
                    boxShadow:
                      '0rem 0.09375rem 0.25rem -0.0625rem rgba(113, 120, 132, 0.5)'
                  }}
                >
                  Create Tag
                </Button>
              )}

              {/*  rendered tags list */}
              {values.tags?.length > 0 && (
                <Stack spacing={2} mt={2}>
                  {values.tags.map((tag, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: '#F9FAFB',
                        borderRadius: '0.5rem',
                        padding: '0.5rem 0.75rem'
                      }}
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Box
                          key={index}
                          sx={{
                            background: '#F5F6F7',
                            borderRadius: '0.375rem',
                            height: '2.25rem',
                            display: 'flex',
                            alignItem: 'center',
                            p: '0.5rem'
                          }}
                        >
                          <Box
                            mr={'0.3125rem'}
                            sx={{
                              display: 'inline'
                            }}
                          >
                            <SvgWrapper
                              icon={TagItemIcon}
                              width=".75rem"
                              height=".75rem"
                              color="#5B5DE3"
                            />
                          </Box>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {`${tag.tagsType} : `}
                          </Typography>
                          <Typography
                            variant="bodyMediumMedium"
                            color="#5B5DE3"
                          >
                            {tag.tagsName}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack direction="row" spacing={1}>
                        <IconButton onClick={() => handleEditTag(index)}>
                          <EditIcon
                            stroke="#667085"
                            height="1.25rem"
                            width="1.25rem"
                          />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteTag(index)}>
                          <DeleteIcon fill="#667085" />
                        </IconButton>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 2
        }}
      >
        <CreateProductReceiptBottomNav
          nextButtonLabel="Review"
          isNextDisabled={!formIsComplete}
        />
      </Box>
    </>
  );
};

export default SingleProductReceiptDetails;
