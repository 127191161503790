import {
  ProductReceiptApiData,
  ProductPickupRequestApiData,
  ProductReceiptCollectionDepotApiData,
  ProductReceiptRequestsApiData
} from 'services/types/productReceipt';
import { MiniProductReceiptApiData } from 'services/types/productReceipt';
import { WithIdTransformed, WithPagination } from 'services/types/common';
import { api } from './baseApi';
import { convertToQueryString } from 'services/urlSearchParams';
import { ProductReceiptRequestInfoApiData } from 'services/types/productReceiptRequest';

type ReleaseProductReceiptQueryArgs = {
  productReceiptId: string;
  data: {
    pickup_code: string;
  };
};

type AssignToBranchQueryArgs = {
  productReceiptId: string;
  data: {
    branch: string;
  };
};

type SchedulePickupArgs = {
  pickupRequestId: string;
  productReceiptId: string;
  data: {
    collection_depot: string;
    pickup_date: string;
  };
};

type GetRecentProductReceiptQueryArgs = {
  params: {
    count: string;
  };
};

type CreatePickupRequestQueryArgs = {
  productReceiptId: string;
  data: {
    firstname: string;
    lastname: string;
    email: string;
    phone_number: string;
    profile_picture: File | null;
    vehicle_make: string | null;
    vehicle_model: string | null;
    vehicle_plate_number: string | null;
    vehicle_color: string | null;
    vehicle_photo: File | null;
  };
};

export type ProductReceiptData = {
  total_quantity: number;
  quoted_price_expiry: string;
};

export type CreateProductReceiptQueryArgs = {
  product_variant: string;
  holders: string[];
  product_receipt_data: ProductReceiptData[];
};
export type CreateProductReceiptComplaintArgs = {
  productReceiptId: string;
  data: {
    referenced_organization: string;
    complaint_type: string;
    complaint_description: string;
  };
};

type ValidatePickupCodeQueryArgs = {
  productReceiptId: string;
  data: {
    pickup_code: string;
  };
};

type GetProductReceiptHistoryQueryArgs = {
  params: {
    page: number;
    pageSize: number;
    start?: string;
    end?: string;
    holders?: string[];
    merchants?: string[];
    closed?: string;
  };
};

type GetPickupRequestsQueryArgs = {
  params: {
    page: string;
    pageSize: string;
  };
};

type GetProductReceiptComplaintTypesProp = {
  type: string;
  description: string;
};

type ResolveProductReceiptComplaintArgs = {
  complaintId: string;
  productReceiptId: string;
};

type GetProductReceiptRequestsArgs = {
  params: {
    limit: string;
  };
};

type PaymentAnalyticsItem = {
  month: string;
  Expected: number;
  Received: number;
};

type PaymentAnalyticsResponse = PaymentAnalyticsItem[];

type PaymentAnalyticsQueryArg = {
  distributor: string;
};

export type UtilizationAnalyticsItem = {
  month: string;
  value: number;
};
export type UtilizationAnalyticsResponse = UtilizationAnalyticsItem[];
export type UtilizationAnalyticsQueryArg = {
  distributor: string;
};

export type PaymentProbabilityResponse = {
  likelihood: number;
  category: string;
  explanation: string;
};

export type PaymentProbabilityQueryArg = {
  distributor: string;
  probability_type: string;
};

// Type for individual product receipt data item in the payload.
export type RequestProductReceiptData = {
  total_quantity: number;
  tags: { key: string; value: string }[];
};

export type CreateRequestProductReceiptQueryArgs = {
  merchant: string;
  customer: string;
  product_variant: string;
  product_receipt_data: RequestProductReceiptData[];
  facility_data: {
    facility_type: string;
    total_amount: string;
    initial_amount: string;
    subsequent_amount: string;
    repayment_schedule: number;
    expiry_date: string; // ISO string
  };
  publish_request: boolean;
};

export const productReceiptApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductReceipt: builder.query<
      ProductReceiptApiData,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    createProductReceipt: builder.mutation<null, CreateProductReceiptQueryArgs>(
      {
        query: data => ({
          url: '{supplyChainProfileId}/product_receipts/',
          method: 'post',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        }),
        invalidatesTags: ['productReceiptMetadata']
      }
    ),
    getProductReceiptActions: builder.query({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/actions/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    getRecentProductReceipts: builder.query<
      WithIdTransformed<MiniProductReceiptApiData>,
      GetRecentProductReceiptQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/recent/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      keepUnusedDataFor: 3600
    }),
    getProductReceiptHistory: builder.query<
      WithPagination<MiniProductReceiptApiData[]>,
      GetProductReceiptHistoryQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/history/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      keepUnusedDataFor: 3600,
      providesTags: ['getProductReceiptHistory']
    }),
    releaseProductReceipt: builder.mutation<
      null,
      ReleaseProductReceiptQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/release/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId },
        'productReceiptMetadata'
      ]
    }),
    startBranchAssignmentWorkflow: builder.mutation<
      null,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/assign/start_workflow/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    assignProductReceiptToBranch: builder.mutation<
      null,
      AssignToBranchQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/assign/complete/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId },
        'productReceiptMetadata'
      ]
    }),
    startEndorsementWorkflow: builder.mutation<
      null,
      { data: { product_receipts: string[] } }
    >({
      query: ({ data }) => ({
        url: '{supplyChainProfileId}/product_receipts/endorsement/start_workflow/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, { data }) =>
        data.product_receipts.map(id => ({
          type: 'productReceipts',
          id: id
        }))
    }),
    endorseProductReceipt: builder.mutation<
      null,
      { data: { product_receipt: string } }
    >({
      query: ({ data }) => ({
        url: '{supplyChainProfileId}/product_receipts/endorsement/complete/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.data.product_receipt }
      ]
    }),
    createPickupRequest: builder.mutation<null, CreatePickupRequestQueryArgs>({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/request/`,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    getPickupRequests: builder.query<
      WithPagination<ProductPickupRequestApiData[]>,
      GetPickupRequestsQueryArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/pickup/request/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    getPickupDepots: builder.query<
      ProductReceiptCollectionDepotApiData[],
      unknown
    >({
      query: () => ({
        url: '{supplyChainProfileId}/product_receipts/pickup/depot/',
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    generatePickupCode: builder.mutation<
      ProductReceiptApiData,
      { productReceiptId: string }
    >({
      query: ({ productReceiptId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/code/generate/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    schedulePickup: builder.mutation<null, SchedulePickupArgs>({
      query: ({ pickupRequestId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/pickup/request/${pickupRequestId}/schedule/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    validatePickupCode: builder.mutation<
      ProductReceiptApiData,
      ValidatePickupCodeQueryArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/pickup/code/validate/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'productReceipts', id: args.productReceiptId }
      ]
    }),
    createProductReceiptReport: builder.mutation<
      null,
      CreateProductReceiptComplaintArgs
    >({
      query: ({ productReceiptId, data }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/complaint/`,
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: ['getCentralVaultProductReceipts']
    }),
    getProductReceiptComplaintTypes: builder.query<
      GetProductReceiptComplaintTypesProp[],
      unknown
    >({
      query: () => {
        return {
          url: '{supplyChainProfileId}/product_receipts/complaint_types/',
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      }
    }),
    resolveProductReceiptComplaint: builder.mutation<
      null,
      ResolveProductReceiptComplaintArgs
    >({
      query: ({ productReceiptId, complaintId }) => ({
        url: `{supplyChainProfileId}/product_receipts/${productReceiptId}/complaint/${complaintId}/resolve/`,
        method: 'patch',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: ['getProductReceiptHistory']
    }),
    getProductReceiptRequests: builder.query<
      ProductReceiptRequestsApiData[],
      GetProductReceiptRequestsArgs
    >({
      query: ({ params }) => {
        const queryParams = convertToQueryString(params);
        return {
          url: `{supplyChainProfileId}/product_receipts/request/?${queryParams}`,
          method: 'get',
          headers: {
            'Content-Type': 'application/json'
          }
        };
      },
      providesTags: () => [{ type: 'requestProductReceipt' }]
    }),
    getProductReceiptRequestInfo: builder.query<
      ProductReceiptRequestInfoApiData,
      { requestId: string }
    >({
      query: ({ requestId }) => ({
        url: `{supplyChainProfileId}/product_receipts/request/${requestId}/`,
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      providesTags: (result, error, { requestId }) => [
        { type: 'productReceiptRequests', id: requestId }
      ]
    }),
    getFacilityPaymentAnalytics: builder.query<
      PaymentAnalyticsResponse,
      PaymentAnalyticsQueryArg
    >({
      query: ({ distributor }) => ({
        url: '{supplyChainProfileId}/analytics/visualizations/facility/payment/',
        method: 'get',
        params: { distributor }
      }),
      transformResponse: (
        response: { month: string; expected: string; received: string }[]
      ): PaymentAnalyticsResponse =>
        response.map(item => ({
          month: item.month,
          Expected: parseFloat(item.expected),
          Received: parseFloat(item.received)
        }))
    }),
    getFacilityUtilizationAnalytics: builder.query<
      UtilizationAnalyticsResponse,
      UtilizationAnalyticsQueryArg
    >({
      query: ({ distributor }) => ({
        url: '{supplyChainProfileId}/analytics/visualizations/facility/utilization/',
        method: 'get',
        params: { distributor }
      }),
      transformResponse: (
        response: { month: string; value: string }[]
      ): UtilizationAnalyticsResponse =>
        response.map(item => ({
          month: item.month,
          value: parseFloat(item.value)
        }))
    }),
    getFacilityPaymentProbability: builder.query<
      PaymentProbabilityResponse,
      PaymentProbabilityQueryArg
    >({
      query: ({ distributor, probability_type }) => ({
        url: '{supplyChainProfileId}/analytics/visualizations/facility/probability/',
        method: 'get',
        params: { distributor, probability_type }
      })
    }),
    createRequestProductReceipt: builder.mutation<
      null,
      CreateRequestProductReceiptQueryArgs
    >({
      query: data => ({
        url: '{supplyChainProfileId}/product_receipts/request/',
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      }),
      invalidatesTags: ['requestProductReceipt']
    })
  }),
  overrideExisting: false
});

export const {
  useGetProductReceiptQuery,
  useCreateProductReceiptMutation,
  useGetProductReceiptActionsQuery,
  useGetProductReceiptHistoryQuery,
  useLazyGetProductReceiptHistoryQuery,
  useLazyGetProductReceiptQuery,
  useGetRecentProductReceiptsQuery,
  useGetPickupRequestsQuery,
  useLazyGetPickupRequestsQuery,
  useGetProductReceiptRequestsQuery,
  useGetPickupDepotsQuery,
  useValidatePickupCodeMutation,
  useCreatePickupRequestMutation,
  useSchedulePickupMutation,
  useReleaseProductReceiptMutation,
  useStartBranchAssignmentWorkflowMutation,
  useStartEndorsementWorkflowMutation,
  useAssignProductReceiptToBranchMutation,
  useEndorseProductReceiptMutation,
  useCreateProductReceiptReportMutation,
  useGetProductReceiptComplaintTypesQuery,
  usePrefetch,
  useResolveProductReceiptComplaintMutation,
  useGetProductReceiptRequestInfoQuery,
  useGetFacilityPaymentAnalyticsQuery,
  useGetFacilityUtilizationAnalyticsQuery,
  useGetFacilityPaymentProbabilityQuery,
  useCreateRequestProductReceiptMutation
} = productReceiptApi;
